/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@400;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import 'material-icons/iconfont/material-icons.css';
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.css');

.mat-toolbar.mat-primary {
  background-color: #49535a;
}
.mat-button-wrapper {
  color: #dde32e;
}
mat-sidenav-content {
  background-color: white;
}

body {
  width: 100%;
  overflow-x: hidden;
}